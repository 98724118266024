import React, { useEffect, useRef, useState } from "react";
import "./orderHistoryItem.css";
import OrderStatusItem from "./orderStatusItem";
import locationIcon from "../../../../assests/image/location_.svg";
import phoneIcon from "../../../../assests/icons/Phone.svg";
import Paper from "../../../../assests/icons/Paper.svg";
import PopUp from "./popUp";
import "./trackOrder.css";
import Cookies from 'js-cookie';
import moengage from "@moengage/web-sdk";
import { fetchRegisterId,PDF_GENERATE, ORDER_DETAIL, WEB_ADDRESS } from "../../../../Constants";
import CustomLoader from "../../../common/customLoader/customLoader";
import isoFormat from "./isoFormat";
import PageNotFoundLogo from '../../../../assests/image/PageNotFound.png'
import variantArray from "../../../common/variantArray";
import { Link } from "react-router-dom";
const orderStatusNo = [ "pending", "packed", "package_shipped", "out_for_delivery", "delivered","cancelled"];


const OrderStatus = ({ orderID_details }) => {
  const url = new URL(window.location.href);
  let orderId = url.searchParams.get("id");
 
  // const ORDER_DETAIL = `https://${admin}/api/OrderDetail`;

  //  let orderId = orderIds.split('+');


  const [orderDetails, setOrderDetails] = useState([]);
  const [orderAmountDetails, setOrderAmountDetails] = useState([]);

  const [confirmPopUp, setConfirmPopUp] = useState(false);
  const [returnId, setReturnId] = useState();
const [loading,setLoading] = useState(false);
  const [orderStatusNumber, setOrderStatusNumber] = useState([]);
const [noPageFound,setNoPageFound] = useState(false)

    

  const moengageEvent = (orderDetails) => {
   // isoFormat();

   let data = {};
   const firstKey = orderDetails.Result[0]?.variant1_name;

   
    let ProductName = [],ProductID =[],Price = [] ,Flavour=[],Size=[],Quantity=[],DateTime=[],PaymentType=[],MRP=[],Note=[];
    orderDetails.Result.map((item) => {
        ProductName.push(item.Title);
        ProductID.push(item.Id)
        Price.push(item.ListingPrice)
        // Flavour.push(item.variant1_value)
        // Size.push(item.variant2_value)
        if (item.variant2_name !== "" && typeof item.variant2_name !== undefined) {
          //console.log(item.variant1_name,item.variant1_value,item.variant2_name,item.variant2_value)
          variantArray(
            data,
            firstKey,
            item.variant1_name,
            item.variant1_value,
            item.variant2_name,
            item.variant2_value,
    
          );
        } else if (
          item.variant1_name !== "" &&
          typeof item.variant1_name !== undefined
        ) {
          //console.log(item.variant1_name,item.variant1_value)
          variantArray(data,firstKey,item.variant1_name, item.variant1_value);
        }
        Quantity.push(item.ProductQuantity)
        PaymentType.push(item.PaymentType)
        MRP.push(item.MRP)
        Note.push(parseInt( item.Note.replace('(',"").replace(')',"").replace('%',"").split(" ")[0]))
    })
    moengage.track_event("Previous_Orderd_Viewed", {
      "ID": `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` , 
      "Order ID" : orderId,
      "Order Status" : orderDetails.Result[0].OrderStatus.toUpperCase().replace(/_/g, ' '),
      "Product Name" :ProductName,
      "Product ID" : ProductID,
      "Order Price": Price,
      "Order Quantity" : Quantity,  
      "Total Pay" : orderDetails.TotalPayableAmount,
      "Coupon Discount" : orderDetails.Discount,
      "Date & Time" : isoFormat(orderDetails.Result[0]?.Date,orderDetails.Result[0]?.Time),
      "OrderType" : PaymentType,
      "Discount Percentage" : Note,
      ...data
      });
  
  
    }


 


  const fetchOrderStatus = async () => {
    try {

      const registerID = fetchRegisterId();  //  Cookies.get("userData");
      if(registerID){
        const response = await fetch(ORDER_DETAIL, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            RegisterId: registerID,
            OrderId: orderId,
            ApplicationType : "web"
          }),
        });
  
        if (!response.ok) {
          throw new Error(
            `Failed to fetch cart items! Status: ${response.status}`
          );
        }
  
  
        const orderDetails = await response.json();
  
        if(orderDetails.Status){
  
          setOrderDetails(orderDetails.Result);
          setOrderAmountDetails(orderDetails);
         
          moengageEvent(orderDetails);
        }
        else{
          //console.log("TRUE")
          
          setNoPageFound(true)
        }
      }
 
 
    } catch (error) {
      //console.log(error.message);
    }
  };
  let userData = Cookies.get("userData") ? JSON.parse(Cookies.get("userData")) : "";
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    },1000)


    fetchOrderStatus();
    //console.log(orderDetails)
    if(orderAmountDetails)
    {}
  }, []);

 
  const handleCancelOrder = () => {
    setConfirmPopUp(true);
  };
  const handleReturnOrder = () => {
      setConfirmPopUp(true);
      setReturnId(orderId)
    
   
  }

  useEffect(() => {
    if(orderDetails && orderDetails[0]){
     
    if(orderDetails[0]?.Status =='aborted'){

      setOrderStatusNumber(["Failed",0])
    }
    else{
      switch (orderDetails[0]?.OrderStatus) {
        case 'Cancelled':
          setOrderStatusNumber(["Cancelled",4])
          break;
        case 'Pending':
          setOrderStatusNumber(["Order Placed",1])
          break;
  
        case 'Delivered':
          setOrderStatusNumber(["Delivered",2])
          break;
  
        default :
        setOrderStatusNumber(["Shipped",3])
        break;
            
      }
    }


   
  
      // let orderStatusN = orderStatusNo.findIndex(status => status === orderDetails[0]?.OrderStatus.toLowerCase());
      // (orderStatusN)
    }
   
  }, [orderDetails])


  const handleDownload = () => {
    window.location.href = orderDetails[0].InvoiceUrl;
  };


console.log(orderStatusNumber[1])
if(loading){
  return (
    <CustomLoader/>
  )

}else{
  return (
    <div className="orderStatusWrapper">
      {!noPageFound && userData && orderDetails && <div className="order__status__final page-width">
        <div className="productsWrapper white_heading">
          {/* <Link to={"/account/orders"} className="returnToOrderHistory">
            <img src={Arrow} className="location__icon turn180" />
            <h6>Back To Order</h6>
          </Link> */}
          <div className="product_status_txt">
            <h5>Order Status</h5>
          </div>
          <div className="trackingWrapper">
            <div className="trackingBox">
              <div className="text">Order Placed</div>
              <div className={`order-${orderStatusNumber[1] > 0 ? 'active' : 'inactive'}`} />

            </div>
            { orderStatusNumber[1] !=4 && orderStatusNumber[1] !=0 && <div className="trackingBox">
              {" "}
              <div className="text">Shipped</div>
              <div className={`order-${orderStatusNumber[1] > 1 ? 'active' : 'inactive'}`} />

            </div> }

            
            <div className="trackingBox">
              {" "}
              <div className={` ${orderStatusNumber[1] === 4 || orderStatusNumber[1] ==0 ? 'red' : 'text'}`}>{orderStatusNumber[1] === 2 ? 'Delivered' : orderStatusNumber[1] === 4 ?  'Cancelled' : "Failed" }</div>
              <div className={`delivered order-${orderStatusNumber[1] == 2 ? 'active' : orderStatusNumber[1] === 4 ||  orderStatusNumber[1] === 0 ? ' dot':'inactive'}`} />

            </div>
          </div>
          <div className="product_title_order white_heading">
            <h5>Products</h5>
            {orderStatusNumber[1] === 2 && <button className="invoice_btn" onClick={handleDownload}>
              <img src={Paper} className="location__icon" />
              Invoice
            </button>}
            {/*  */}
          </div>
          <div className="productsDisplayContainer">
            <div className="leftContainer">
              {orderDetails &&
                orderDetails.map((orderDetail) => (
                  <OrderStatusItem
                    orderDetail={orderDetail}
                    key={orderDetail.Id}
                  />
                ))}
            </div>
            <div className="rightContainer">
              <div className="rightContainer_wrapper ">
                <div className="info">
                  {" "}
                  <div className=" text"> Order Status</div>
                  <div
                    className={`idText ${
                      orderDetails && orderDetails.length > 0 ? 
                      orderStatusNumber[1] === 0 || orderStatusNumber[1] === 4
                          ? "cancelled"
                          :
                            "completed"
                        : "" 
                    }`}
                  >

                    {orderDetails && orderDetails[0] && orderDetails[0]?.Status !=='aborted'  ? orderStatusNumber[0] : "FAILED"}
                  </div>
                </div>
                <div className="info">
                  {" "}
                  <div className=" text"> Order Id</div>
                  <div className="actual_price">#{orderId}</div>
                </div>
              </div>
              <div className="rightContainer_wrapper ">
                <div className="actual_price">Delivered To</div>
                <div className=" addressInfo">
                  {" "}
                  <img
                    src={locationIcon}
                    className="location__icon"
                    alt="locationIcon"
                  />

                  <div className="location_info">{ orderDetails && orderDetails[0] && orderDetails[0].Address}</div>
                </div>
                <div className=" addressInfo">
                  {" "}
                  <img src={phoneIcon} className="location__icon" alt="Phone" />
                  <div className="location_info">+91 {userData.phoneNumber?.replace("+91","") || userData.MobileNo?.replace("+91","")}</div>

                </div>
              </div>
              {orderAmountDetails && <div className="rightContainer_wrapper text">
                <div className="info">
                  {" "}
                  <div className=" text"> Amount to pay</div>
                  <div className="actual_price">
                    ₹{orderAmountDetails.SubTotalWithTax}
                  </div>
                </div>
           {orderAmountDetails.Discount >0 &&  <div className="info">
                  {" "}
                  <div className=" text"> Discount</div>
                  <div className="discounted">
                    -₹{orderAmountDetails.Discount}
                  </div>
                </div>}
               {orderAmountDetails.PrepaidDiscount >0 && <div className="info">
                  {" "}
                  <div className=" text"> Prepaid Discount</div>
                  <div className="discounted">
                    -₹{orderAmountDetails.PrepaidDiscount}
                  </div>
                </div>}
                <div className="info">
                  {" "}
                  <div className=" text"> Delivery Charges</div>
                  <div className="delivery_charge">
                    ₹{orderAmountDetails.DelhiveryCharges}
                  </div>
                </div>
                <div className="dot__line"></div>
                <div className="info">
                  {" "}
                  <div className="actual_price"> Total</div>
                  <div className="discount_price">
                    ₹{Math.round(orderAmountDetails?.TotalPayableAmount)}
                  </div>
                </div>
              </div>}
              {orderAmountDetails.CancelOrder === "Yes" && orderDetails && orderDetails[0] && orderDetails[0].OrderStatus === "Pending" && (
                <div className="cancel_wrapper">
                  <button
                    className="btn_primary"
                    onClick={handleCancelOrder}
                  >
                    Cancel Order
                  </button>
                </div>
              )}
              { orderAmountDetails.TrackOrder && (
                <a className="cancel_wrapper" target="_blank" href={orderAmountDetails.TrackOrder}>
                <button
                  className="btn_primary"
                  
                >
                 Track Order
                </button>
              </a>
              )
                
              }
              {orderAmountDetails.Returnable === "No" && (
                <div className="cancel_wrapper">
                  <button
                    className="btn_primary"
                    onClick={handleReturnOrder}
                  >
                    Return Order
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>}
      {( noPageFound || !userData)&& <div className="order__status__final page-width flex-center">  
           <img src={PageNotFoundLogo}/>
      <Link className='btn_primary' to={'/'}>Continue Shopping</Link></div>}
      <div id="successfullModal" className="successfull_modal">
        {confirmPopUp && orderId && (
          <PopUp setConfirmPopUp={setConfirmPopUp} orderId={orderId} returnId={false} orderAmountDetails={orderAmountDetails} />
          //  <SuccessfullPop/>
        )}
        {confirmPopUp && returnId && (
          <PopUp setConfirmPopUp={setConfirmPopUp} returnId={returnId} />
          //  <SuccessfullPop/>
        )}
      </div>
    </div>
  );
};
}


export default OrderStatus;
